import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**
 * 活动报名 - 分页查询
 * @param params
 */
export function getActivityList(params) {
  return request({
    url: envInfo.bgApp.customerGSPath + '/activityEnrollment/getPageByParam',
    method: 'get',
    params,
  });
}

/**
 * 活动报名 - 新建活动报名
 * @param params
 */
export function addActivityEnrollment(params) {
  return request({
    url: envInfo.bgApp.customerGSPath + '/activityEnrollment/create',
    method: 'post',
    data: params,
  });
}

/**
 * 活动报名 - 删除活动报名
 * @param params
 */
export function deleteActivityEnrollment(params) {
  return request({
    url: envInfo.bgApp.customerGSPath + '/activityEnrollment/batchDelete',
    method: 'post',
    data: params,
  });
}

/**
* 活动报名 - 获取活动报名详情
* @param params
*/
export function getActivityEnrollment(params) {
  return request({
    url: envInfo.bgApp.customerGSPath + '/activityEnrollment/getDetails',
    method: 'get',
    params,
  });
}

/**
* 活动报名 - 修改活动报名
* @param params
*/
export function editActivityEnrollment(params) {
  return request({
    url: envInfo.bgApp.customerGSPath + '/activityEnrollment/update',
    method: 'put',
    data: params,
  });
}

/**
* 活动报名 - 下载
* @param params
*/
export function downloadServiceGuide(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/serviceGuide/download',
    method: 'get',
    params,
  });
}

/**
* 活动报名 - 查看详情
* @param params
*/
export function getActivityDetails(params) {
  return request({
    url: envInfo.bgApp.customerGSPath + '/activityEnrollment/getDetails',
    method: 'get',
    params,
  });
}

/**
* 活动报名 - 导出
* @param params
*/
export function activityListExport(params) {
  return request({
    url: envInfo.bgApp.customerGSPath + '/activityEnrollment/export',
    method: 'get',
    params,
  });
}

/**
* 活动报名 - 查看-导出
* @param params
*/
export function applicationListExport(params) {
  return request({
    url: envInfo.bgApp.customerGSPath + '/activityEnrollment/exportDetail',
    method: 'get',
    params,
  });
}

/**
* 活动报名 - 查看-报名情况
* @param params
*/
export function getApplicationDetails(params) {
  return request({
    url: envInfo.bgApp.customerGSPath + '/activityEnrollment/getDetailPageByParam',
    method: 'get',
    params,
  });
}