<template>
    <div class="even-detail">
        <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="活动标题" prop="activityTitle">
                        <el-input v-model="ruleForm.activityTitle"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="活动地点" prop="activityPlace">
                        <el-input v-model="ruleForm.activityPlace"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="活动开始时间" prop="startTime">
                        <el-date-picker style="width: 19.375rem;" v-model="ruleForm.startTime" type="datetime"
                            placeholder="选择开始时间" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="活动结束时间" prop="endTime">
                        <el-date-picker style="width: 19.375rem;" v-model="ruleForm.endTime" type="datetime"
                            placeholder="选择开始时间" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="允许报名开始时间" prop="enrollStartTime">
                        <el-date-picker style="width: 19.375rem;" v-model="ruleForm.enrollStartTime" type="datetime"
                            placeholder="选择开始时间" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="允许报名结束时间" prop="enrollEndTime">
                        <el-date-picker style="width: 19.375rem;" v-model="ruleForm.enrollEndTime" type="datetime"
                            placeholder="选择开始时间" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item align="right">
                <el-button @click="resetForm()">取消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {
    addActivityEnrollment,
    getActivityEnrollment,
    editActivityEnrollment
} from "@/api/ruge/gsPark/customerService/eventregistration.js";
export default {
    name: "evenDialog",
    props: {
        // 弹窗类型
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
        typeFlag: {
            required: false,
        },
    },
    data() {
        return {
            // currentState: "add",
            ruleForm: {
                activityTitle: '',
                activityPlace: '',
                startTime: '',
                endTime: '',
                enrollStartTime: '',
                enrollEndTime: ''
            },
            rules: {
                activityTitle: [
                    { required: true, message: "活动标题为必填项", trigger: "blur" },
                ],
                activityPlace: [
                    { required: true, message: "活动地点为必填项", trigger: "blur" },
                ],
                startTime: [
                    { required: true, message: "活动开始时间为必填项", trigger: "blur" },
                ],
                endTime: [
                    { required: true, message: "活动结束时间为必填项", trigger: "blur" },
                ],
                enrollStartTime: [
                    { required: true, message: "允许活动开始时间为必填项", trigger: "blur" },
                ],
                enrollEndTime: [
                    { required: true, message: "允许活动结束时间为必填项", trigger: "blur" },
                ],
            }
        }
    },
    methods: {
        resetForm() {
            this.$refs["ruleForm"].resetFields();
            this.$emit("close", false);
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitHandler();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getActivityDetail(id) {
            getActivityEnrollment({ id }).then((res) => {
                if (res.code === 200) {
                    this.ruleForm.activityTitle = res.data.activityTitle
                    this.ruleForm.activityPlace = res.data.activityPlace
                    this.ruleForm.startTime = new Date(res.data.startTime).getTime();
                    this.ruleForm.endTime = new Date(res.data.endTime).getTime();
                    this.ruleForm.enrollStartTime = new Date(res.data.enrollStartTime).getTime();
                    this.ruleForm.enrollEndTime = new Date(res.data.enrollEndTime).getTime();
                    this.ruleForm.imageId = res.data.imageId
                } else {
                    this.$message.warning("获取活动详情失败！");
                }
            })
        },
        parseParams(params) {
            switch (this.dialogStatus) {
                case "add":
                    params.typeFlag = 0;
                    break;
                case "edit":
                    params.typeFlag = this.typeFlag;
                    params.id = this.id;
                    break;
            }
            return params;
        },
        submitHandler() {
            let params = { ...this.ruleForm };
            params = this.parseParams(params);
            if (this.dialogStatus === "add") {
                addActivityEnrollment(params).then((res) => {
                    if (res.code === 200) {
                        this.$message.success("新增报名成功");
                        this.$emit("close", true);
                    } else {
                        this.$message.warning("新增报名失败！");
                    }
                })
            } else {
                editActivityEnrollment(params).then((res) => {
                    if (res.code === 200) {
                        this.$message.success("修改活动成功");
                        this.$emit("close", true);
                    } else {
                        this.$message.warning("修改活动失败！");
                    }
                });
            }
        }
    },
    watch: {
        id: {
            handler(id) {
                if (!id) return;
                this.getActivityDetail(id);
            },
            immediate: true,
        },
    }
}
</script>